import { jsx, Grid } from "theme-ui";
import { MdWeb, MdShoppingCart, MdSearch, MdMoreHoriz, MdDesignServices, MdSpeakerNotes, MdShare, MdWebStories } from "react-icons/md";
import { IoAnalyticsSharp } from "react-icons/io5";
import ServiceCard from "../../../../src/components/serviceCard";
import * as React from 'react';
export default {
  jsx,
  Grid,
  MdWeb,
  MdShoppingCart,
  MdSearch,
  MdMoreHoriz,
  MdDesignServices,
  MdSpeakerNotes,
  MdShare,
  MdWebStories,
  IoAnalyticsSharp,
  ServiceCard,
  React
};